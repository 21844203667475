import React from "react";
import { LovEnumIdentifier } from "../../../Types/Snapshot";
import { Barnehageloven } from "./Barnehageloven";
import { ReaksjonKravOmTilbakebetaling } from "../../Common/ReaksjonKravOmTilbakebetaling/ReaksjonKravOmTilbakebetaling";
import { getText } from "../../../Overskrifts/Overskrifts";
import { Privatskoleloven } from "./Privatskoleloven";
import { IReaksjonProps } from "../../Common/reaksjonInterfaces";

export const EndeligRapportKravOmTilbakebetaling: React.FC<IReaksjonProps> = ({
  snapshot,
  withoutObfuscationBackground,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonTilbakebetalingAvTilskuddVedtak,
  );
  if (!snapshot.reaksjoner?.kravOmTilbakebetaling?.isValgt) {
    return null;
  }

  return (
    <>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven ? (
        <Barnehageloven snapshot={snapshot} heading={heading} />
      ) : (
        <Privatskoleloven snapshot={snapshot} heading={heading} />
      )}
      <ReaksjonKravOmTilbakebetaling
        snapshot={snapshot}
        isForelopigRapport={false}
        withoutObfuscationBackground={withoutObfuscationBackground}
      />
    </>
  );
};
